import qs from 'query-string';

import { requestApi, requestOAuth } from '../utils/request';
import {
  LoginData,
  User,
  InfoAccountToUpdate,
  BusinessTypeToUpdate,
  PreferredGenresToUpdate,
  TargetGroupToUpdate,
  Song,
  FetchTracksInfos,
  InfoLanguageToUpdate,
  OnBoardingSettings,
  MoodToUpdate,
  ResetPasswordData,
  RenewPasswordData,
  ResSongsSetting,
  ItemSchedule,
  AppInfo,
  Zone,
  MediaToPlay,
  Station,
  SettingsForStations,
  PlayingTrack,
  Calendar
} from './SoundSuitServiceTypes';

/** Settings Application */
export const checkAppInfo = () => requestApi<AppInfo>('/app-info', 'GET', false, false);

/** Auth */
export const login = (data: LoginData) => requestOAuth<null>(false, data.email, data.password);
export const logout = () => requestApi<boolean>('/logout', 'POST', false, true);
export const register = (data: any) => requestApi<any>('/register', 'POST', data, false);
export const refreshToken = () => requestOAuth<null>(true);
export const resetPassword = (data:ResetPasswordData) => requestApi<any>('/reset_password', 'POST', data, true);
export const newPassword = (data:RenewPasswordData) => requestApi<any>('/reset_password/password', 'POST', data, true);

/** Player */
export const fetchListOfTracks = (data: FetchTracksInfos) => requestApi<Song[]>('/next', 'GET', data, true);
export const getLikedSongs = () => requestApi<ResSongsSetting>('/liked', 'GET', false, true);
export const likeSongs = (songsToLike: string[]) => requestApi<string>('/liked', 'PUT', {songIds: songsToLike}, true);
export const unlikeSongs = (songsToUnlike: string[]) => requestApi<string>('/liked/delete', 'POST', {songIds: songsToUnlike}, true);
export const getBannedSongs = () => requestApi<ResSongsSetting>('/banned', 'GET', false, true);
export const banSongs = (songsToBan: string[]) => requestApi<string>('/banned', 'PUT', {songIds: songsToBan}, true);
export const unbanSongs = (songsToUnBan: string[]) => requestApi<string>('/banned/delete', 'POST', {songIds: songsToUnBan}, true);
export const addToLastPlayed = (songId: string) => requestApi<any>('/last_played', 'POST', {songId}, true);

/** Account */
export const loadUserProfile = () => requestApi<User>('/userinfo', 'GET', false, true);
export const updateUserProfile = (data: InfoAccountToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const updateLanguageProfile = (data: InfoLanguageToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);

/** Settings */
export const updateBusinessType = (data: BusinessTypeToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const updateTargetGroup = (data: TargetGroupToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const updateMood = (data: MoodToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const updatePreferredGenres = (data: PreferredGenresToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const saveOnBoardingSettings = (data: OnBoardingSettings) => requestApi<string>('/user/settings', 'POST', data, true);
export const saveSettingsForStations = (data: SettingsForStations) =>
  requestApi<string>('/user/onboarding', 'POST', data, true);

/** Schedule */
export const getScheduleProgram = () => requestApi<{timeslots: ItemSchedule[]}>('/timeslots', 'GET', false, true);
export const getCurrentScheduleItem = (timezone: string) => requestApi<{timeslots: ItemSchedule}>(`/timeslots/current?timezone=${timezone}`, 'GET', false, true);
export const getScheduleItem = (id: string) => requestApi<ItemSchedule>(`/timeslots/${id}`, 'GET', false, true);
export const setScheduleItem = (data: ItemSchedule) => requestApi<any>('/timeslots', 'POST', data, true);
export const updateScheduleItem = ({id, data}: {id: string, data: ItemSchedule}) => requestApi<any>(`/timeslots/${id}`, 'PATCH', data, true);
export const deleteScheduleItem = (id: string) => requestApi<string>(`/timeslots/${id}`, 'DELETE', false, true);
export const deleteAllScheduleItems = () => requestApi<string>(`/timeslots`, 'DELETE', false, true);
export const deleteSelectedScheduleItems = (tabIds: string[]) => requestApi<string>(`/timeslots?${qs.stringify({ids: tabIds}, {arrayFormat: 'bracket'})}`, 'DELETE', false, true);

export const getCalendar = (id: string) => requestApi<Calendar>(`/calendars/${id}`, 'GET', false, true);

/** Zones */
export const getZones = () => requestApi<Zone[]>(`/zones`, 'GET', false, true);
export const getZoneById = (id: string) => requestApi<Zone>(`/zones/${id}`, 'GET', false, true);
export const setMediaToZone = (id: string, media: MediaToPlay) => requestApi<Zone>(`zones/${id}`, 'PATCH', {mediaToPlay: media}, true);
export const setTrackPlayingForZone = (id: string, data: PlayingTrack) => requestApi<Zone>(`zones/${id}/playing`, 'POST', data, true);

/** Stations */
export const getStationById = (id: string) => requestApi<Zone>(`/stations/${id}`, 'GET', false, true);
export const getStations = () => requestApi<Station[]>(`/stations`, 'GET', false, true);

/** Locations */
export const getLocations = () => requestApi<any>(`/locations`, 'GET', false, true);
export const getLocationById = (id: string) => requestApi<any>(`/locations/${id}`, 'GET', false, true);
